<template>
  <v-dialog v-model="value" persistent width="500">
    <v-card elevation="10" class="mx-auto">
      <v-card-title>Queue Reservation Result</v-card-title>
      <v-card-subtitle
        >*please print or screenshot this page for your records</v-card-subtitle
      >
      <div id="printMe">
        <v-list>
          <v-subheader>Queue Reservation Result</v-subheader>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-subtitle>QUEUE ID</v-list-item-subtitle>
              <v-list-item-title>
                <h1>Q-{{ queueId }}</h1>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-divider></v-divider>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-subtitle>DATE</v-list-item-subtitle>
              <v-list-item-title>
                <h1>{{ queueDate }}</h1>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-divider></v-divider>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-subtitle>TIME</v-list-item-subtitle>
              <v-list-item-title>
                <h1>{{ queueTime }}</h1>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </div>
      <v-card-actions>
        <v-btn plain @click="print">
          <v-icon>mdi-printer</v-icon>
          PRINT
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn plain @click="closeDialog"> CLOSE </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { view } from "../../api/queue";
export default {
  props: {
    value: {
      type: Boolean,
      default: false
    },
    queueId: [String, Number]
  },
  data() {
    return {
      queueDate: "",
      queueTime: "",
      loading: false
    };
  },
  created() {},
  watch: {
    queueId(v) {
      if (v == "") return;
      this.getQueueData();
    }
  },
  methods: {
    getQueueData() {
      if (this.queueId == "") return;
      view(this.queueId).then((data) => {
        this.queueDate = data.queueDate;
        this.queueTime = data.queueTime;
      });
    },
    print() {
      this.$htmlToPaper("printMe");
    },
    closeDialog() {
      this.$emit("close");
    }
  }
};
</script>

<style>
</style>