import req from "../utils/req";

export function reserve(data) {
  return req({
    url: `/vip_reserve`,
    method: "post",
    data: data
  });
}

export function worker_reserve(data) {
  return req({
    url: `/worker_reserve`,
    method: "post",
    data: data
  });
}

export function freeslot(data) {
  return req({
    url: `/freeSlot`,
    method: "post",
    data: data
  });
}
