<template>
  <v-dialog v-model="dialog" persistent max-width="600px">
    <v-card>
      <v-card-title>
        <span class="headline">รายละเอียดแรงงานต่างด้าว</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-form ref="modalForm" v-model="valid" lazy-validation>
            <v-text-field
              label="เลขอ้างอิงคนต่างด้าว *"
              v-model="value.refId"
              :counter="13"
              :rules="ridRules"
              maxlength="13"
              required
            ></v-text-field>
            <v-text-field
              label="ชื่อ-นามสกุล *"
              v-model="value.name"
              :rules="engNameRules"
            ></v-text-field>

            <v-select
              :items="['Myanmar', 'Cambodia', 'Laos']"
              v-model="value.national"
              label="สัญชาติ *"
              :rules="nameRules"
            ></v-select>

            <v-select
              :items="['ชาย', 'หญิง']"
              v-model="value.gender"
              label="เพศ *"
              :rules="nameRules"
            ></v-select>
          </v-form>
        </v-container>
        <small class="red--text text--darken-4">* จำเป็นต้องกรอกข้อมูล</small>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="closeDialog">
          Close
        </v-btn>
        <v-btn color="blue darken-1" text @click="saveData" :disabled="!valid">
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    value: {
      type: Object,
      default() {
        return {};
      }
    },
    dialog: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      ridRules: [
        v => !!v || "จำเป็นต้องกรอกข้อมูล",
        v => /\d{13}/.test(v) || "จำนวน 13 หลักและเป็นตัวเลขเท่านั้น"
        // v => (v && v.length >= 13) || "เลขอ้างอิงต้องมี 13 หลัก"
      ],
      engNameRules: [
        v => !!v || "จำเป็นต้องกรอกข้อมูล",
        v => /[A-Za-z]+/.test(v) || "กรอกเป็นภาษาอังกฤษ"
      ],
      nameRules: [v => !!v || "จำเป็นต้องกรอกข้อมูล"],
      valid: false
    };
  },
  methods: {
    closeDialog() {
      this.$emit("closeDialog");
    },
    saveData() {
      if (this.$refs.modalForm.validate()) {
        this.$refs.modalForm.resetValidation();
        this.$emit("save");
      }
    }
  }
};
</script>

<style></style>
