<template>
  <v-container fluid>
    <v-alert dismissible type="error" v-if="errorMsg != ''">{{
      errorMsg
    }}</v-alert>
    <v-row>
      <v-col lg="4">
        <v-card elevation="10" class="mx-auto">
          <v-app-bar color="indigo darken-2" flat dark>
            <v-toolbar-title>
              <v-icon>mdi-account-details</v-icon>
              กรอกรายละเอียด
            </v-toolbar-title>
          </v-app-bar>
          <v-container>
            <v-form ref="form" v-model="valid" lazy-validation>
              <v-text-field
                v-model="name"
                :counter="100"
                :rules="nameRules"
                label="ชื่อ-นามสกุล/ชื่อนิติบุคคล"
                required
              ></v-text-field>

              <v-text-field
                v-model="cid"
                :counter="13"
                :rules="cidRules"
                maxlength="13"
                label="เลขประจำตัวประชาชน/เลขที่นิติบุคคล"
                hint="รูปแบบการกรอกข้อมูล (1509921234231)"
                required
              ></v-text-field>

              <v-text-field
                v-model="address"
                :counter="250"
                :rules="addressRules"
                maxlength="250"
                label="ที่อยู่"
                hint="ex. (110 หมู่ 2 ต.บางปะอิน อ.บางปะอิน จ.อยุธยา)"
                required
              ></v-text-field>

              <v-text-field
                v-model="phone"
                :rules="phoneRules"
                hint="รูปแบบการกรอกข้อมูล (0861234567)"
                label="เบอร์โทรศัพท์"
                required
              ></v-text-field>

              <!-- Date Picker -->
              <v-menu
                v-model="menu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="date"
                    label="จองคิววันที่"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="date"
                  @input="menu = false"
                ></v-date-picker>
              </v-menu>
              <!-- end Date Picker -->
              <v-select
                :items="['09.00-12.00', '13.00-16.00']"
                v-model="time"
                label="ช่วงเวลา"
              ></v-select>
              <v-text-field v-model="remark" label="บันทึกอื่นๆ"></v-text-field>
            </v-form>
            <small class="indigo--text"
              >* วันที่เลือกเหลืออีก {{ free_queue }} คิว</small
            >
            <small class="red--text" v-if="!free">
              * วันที่เลือกไม่สามารถจองคิวได้</small
            >
          </v-container>
        </v-card>
      </v-col>
      <!-- right -->
      <v-col>
        <v-card class="mx-auto">
          <v-app-bar color="indigo darken-2" flat dark>
            <v-toolbar-title>
              <v-icon>mdi-account-multiple</v-icon>
              รายชื่อแรงงานต่างด้าว
            </v-toolbar-title>
            <v-spacer />
            <v-row justify="end">
              <v-icon>mdi-account</v-icon> {{ foreignworker }}
            </v-row>
          </v-app-bar>
          <v-container>
            <v-list two-line>
              <v-list-item>
                <v-list-item-content>
                  <v-row>
                    <v-col>
                      <v-checkbox
                        v-model="auto_gen_worker"
                        label="สร้างข้อมูลแรงงาน"
                      ></v-checkbox>
                    </v-col>
                    <v-col>
                      <v-text-field
                        v-model="gen_worker_total"
                        label="จำนวน (คน)"
                        :disabled="!auto_gen_worker"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-list-item-content>
                <v-row align="center" justify="end">
                  <v-btn @click="() => (workerDialog = true)">
                    <v-icon class="mr-1"> mdi-plus </v-icon>
                    เพิมรายชื่อ
                  </v-btn>
                </v-row>
              </v-list-item>
              <v-divider></v-divider>
              <template v-for="(item, index) in workerList">
                <v-list-item :key="item.rid">
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ item.name }}
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      {{ item.refId }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                  <v-row align="center" justify="end">
                    <v-btn @click="editWorker(index)" icon>
                      <v-icon> mdi-pencil </v-icon>
                    </v-btn>
                    <v-btn @click="removeWorker(index)" icon>
                      <v-icon> mdi-account-remove </v-icon>
                    </v-btn>
                  </v-row>
                </v-list-item>
                <v-divider :key="index"></v-divider>
              </template>
            </v-list>
          </v-container>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              :disabled="!valid"
              color="success"
              class="mr-4"
              :loading="loading"
              @click="validate"
            >
              บันทึกข้อมูล
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
      <form-modal
        v-model="workerDetail"
        :dialog="workerDialog"
        @closeDialog="() => (workerDialog = false)"
        @save="addWorkerList"
      />
      <queue-dialog
        v-model="queueDialog"
        :queueId="queueId"
        @close="closeQueueDialog"
      />
    </v-row>
  </v-container>
</template>

<script>
import formModal from "./components/formModal";
import queueDialog from "./components/queueConfirm";
import { reserve, freeslot } from "../api/reserve";
export default {
  components: { formModal, queueDialog },
  data() {
    return {
      valid: false,
      name: "",
      nameRules: [v => !!v || "จำเป็นต้องกรอกข้อมูล"],
      cid: "",
      cidRules: [
        v => !!v || "จำเป็นต้องกรอกข้อมูล",
        v => /\d{13}/.test(v) || "ตัวเลขเท่านั้นและต้องมี 13 หลัก"
      ],
      phone: "",
      phoneRules: [
        v => !!v || "จำเป็นต้องกรอกข้อมูล",
        v => /\d+/.test(v) || "เฉพาะตัวเลขไม่ต้องเว้นวรรคหรือใส่เครื่องหมาย"
      ],
      email: "",
      emailRules: [
        // v => !!v || "E-mail is required",
        v => (v && /.+@.+\..+/.test(v)) || "กรอก Email ให้ถูกต้อง"
      ],
      menu: false,
      date: new Date().toISOString().substr(0, 10),
      time: "09.00-12.00",
      remark: "",
      address: "",
      addressRules: [v => !!v || "จำเป็นต้องกรอกข้อมูล"],
      foreignworker: "",
      checkbox: false,
      lineUserId: "",
      lineProfilePicture: "",
      lineDisplayName: "",
      queueDialog: false,
      workerDialog: false,
      workerIsUpdate: false,
      updateIndex: -1,
      auto_gen_worker: false,
      gen_worker_total: 0,
      workerDetail: {
        name: "",
        refId: "",
        gender: "",
        national: ""
      },
      workerList: [],
      dataSaved: false,
      queueId: "",
      errorMsg: "",
      token: "",
      loading: false,
      free: true,
      free_queue: 80
    };
  },
  created() {},
  watch: {
    workerList(v) {
      this.foreignworker = v.length;
      if (v.length > 0) {
        this.errorMsg = "";
        this.$refs.form.validate();
      }
    },
    date(v) {
      this.checkFreeSlot();
    },
    time() {
      this.checkFreeSlot();
    },
    gen_worker_total() {
      this.errorMsg = "";
      this.valid = true;
    }
  },
  methods: {
    validate() {
      if (
        this.workerList.length > this.free_queue ||
        this.gen_worker_total > this.free_queue
      ) {
        this.errorMsg = "จำนวนแรงงานเกินกว่า จำนวนคิวที่ว่าง";
        this.valid = false;
        return;
      }

      if (this.$refs.form.validate()) {
        if (!this.auto_gen_worker) {
          if (this.workerList.length == 0) {
            this.errorMsg = "กรุณากรอกข้อมูลแรงงาน";
            this.valid = false;
            return;
          }
        }
        var data = {
          workers: this.workerList,
          name: this.name,
          email: this.email,
          phone: this.phone,
          cid: this.cid,
          address: this.address,
          date: this.date,
          time: this.time,
          remark: this.remark,
          auto_gen_worker: this.auto_gen_worker,
          gen_worker_total: this.gen_worker_total
        };
        this.loading = true;
        reserve(data)
          .then(res => {
            console.log(res);
            if (res.success) {
              this.dataSaved = true;
              this.queueId = res.queueId;
              this.queueDialog = true;
            }
          })
          .finally(() => (this.loading = false));
      }
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    addWorkerList() {
      if (this.workerIsUpdate && this.updateIndex != -1) {
        this.updateWorker();
        return;
      }
      this.workerList.push(Object.assign({}, this.workerDetail));
      this.clearWorkerDetail();
      this.workerDialog = false;
    },
    updateWorker() {
      Object.assign(this.workerList[this.updateIndex], this.workerDetail);
      this.clearWorkerDetail();
      this.workerDialog = false;
      this.updateIndex = -1;
      this.workerIsUpdate = false;
    },
    removeWorker(index) {
      this.workerList.splice(index, 1);
    },
    editWorker(index) {
      this.updateIndex = index;
      Object.assign(this.workerDetail, this.workerList[index]);
      this.workerIsUpdate = true;
      this.workerDialog = true;
    },
    clearWorkerDetail() {
      this.workerDetail = {
        name: "",
        refId: "",
        gender: "",
        national: ""
      };
    },
    closeQueueDialog() {
      this.$refs.form.reset();
      this.queueId = "";
      this.queueDialog = false;
      this.workerList = [];
    },
    preventNav(event) {
      if (this.dataSaved) return;
      event.preventDefault();
      event.returnValue = "";
    },
    checkFreeSlot() {
      freeslot({ date: this.date, time: this.time }).then(res => {
        this.free = res.can_reserve;
        this.free_queue = 40 - res.total;

        if (!this.free) this.valid = false;
      });
    }
  },
  beforeMount() {
    window.addEventListener("beforeunload", this.preventNav);
    this.$once("hook:beforeDestroy", () => {
      window.removeEventListener("beforeunload", this.preventNav);
    });
  },

  beforeRouteLeave(to, from, next) {
    if (!this.dataSaved) {
      if (!window.confirm("Leave without saving?")) {
        return;
      }
    }
    next();
  }
};
</script>

<style></style>
